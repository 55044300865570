import type { AvoInspectorEnvValueType } from 'avo-inspector/dist/AvoInspectorEnv'

declare global {
  var __vitest_environment__: boolean
}

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isLive = () => process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === 'PRD'

export const isAcceptance = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === 'ACC'

export const isTesting = () =>
  process.env.NEXT_PUBLIC_ENVIRONMENT_TYPE === 'TST'

export const isPreview = () => process.env.VERCEL_ENVIRONMENT === 'preview'

export const isBrowser = () => typeof window !== 'undefined'

export const isServer = () => !isBrowser()

export const isVitest = () => Boolean(globalThis.__vitest_environment__)

export const isCI = () => process.env.CI === 'true'

export type EnvironmentType = 'TST' | 'ACC' | 'PRD'

export type EnvironmentName = 'dev' | 'tst' | 'acc' | 'prd'

export const getAvoInspectorEnv = (): AvoInspectorEnvValueType => {
  return (isLive() ? 'prod' : 'dev') as unknown as AvoInspectorEnvValueType
}

export const environmentName = () =>
  isDevelopment() ? 'dev' : isTesting() ? 'tst' : isAcceptance() ? 'acc' : 'prd'
